import React from 'react';
import Layout from '../components/common/layout/layout';
import {
    graphql
} from 'gatsby'
import Img from 'gatsby-image';

import styled from "styled-components"

import GetStarted from "../components/sections/getstarted"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import {
    Container
} from "../components/global"
import Footer from "../components/sections/footer"

import { useFirebase } from "gatsby-plugin-firebase"

function BlogPost(props) {
    const post = props.data.markdownRemark;
    const {
        title
    } = post.frontmatter;

    useFirebase(firebase => { firebase.analytics().logEvent("page_view" + title) }, [])

    return (
        <Layout>
        <SEO title="apocha" />
        <Navigation />
        <BlogPostContainer>
                <div>
                    <h1>{title}</h1>
                    <HeaderImg fluid={post.frontmatter.featuredImage.childImageSharp.fluid} />
                    <span>{post.frontmatter.date}</span>
                    <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </div>
        </BlogPostContainer>
        <GetStarted/>
        <Footer />
      </Layout>
    )
}

export default BlogPost;

export const query = graphql`

 query PostQuery($slug: String!) {
     markdownRemark(fields: { slug: { eq: $slug } }) {
       html
       frontmatter {
        title
        description
        date(formatString: "Do MMMM YYYY", locale: "de")
        featuredImage {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 786) {
              ...GatsbyImageSharpFluid
            }
          }
       }
       }
   }
}
`
const BlogPostContainer = styled(Container)
`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`

const HeaderImg = styled(Img)
`
  margin: 0px 0px 25px 0px;
`
